import { Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"

const Resources = () => {
  return (
    <Layout>
      <div className="resources_for_refugee_claimants__picture" />
      <section className="claimants__detail">
        <h1 className="claimants__detail-title">
          Resources for Refugee Claimants
        </h1>
        <p>
          Making a refugee claim is a legal process that is complex and can be
          difficult to navigate. Getting a legal representative as soon as
          possible will help. You can also find some resources in this section
          to help you understand the process better.
        </p>
        <h4>What is the process?</h4>
        <ul className="claimants__list">
          <li>
            Visit this website to help you visualize the different steps of the
            claim process.{" "}
            <a
              href="https://stepstojustice.ca/questions/refugee-law/can-i-make-refugee-claim-canada/"
              target="_blank"
              rel="noreferrer"
            >
              https://stepstojustice.ca/questions/refugee-law/can-i-make-refugee-claim-canada/
            </a>
          </li>
          <li>
            This website will help answer many of your questions about the
            refugee claim process. You can also learn about your rights related
            to housing, employment, income and more.{" "}
            <a
              href="https://stepstojustice.ca/legal-topic/refugee-law/"
              target="_blank"
              rel="noreferrer"
            >
              https://stepstojustice.ca/legal-topic/refugee-law/
            </a>
          </li>
          <li>
            This video from the Immigration and Refugee Board (IRB) gives an
            overview of how the Canadian refugee determination system works:{" "}
            <a
              href="https://www.youtube.com/watch?v=gG52QiQr574"
              target="_blank"
              rel="noreferrer"
            >
              https://www.youtube.com/watch?v=gG52QiQr574
            </a>
          </li>
          <li>
            You might also want to look at the official Government of Canada
            information about making a refugee claim, irregular border
            crossings, appeals, services and other helpful information:{" "}
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees.html"
              target="_blank"
              rel="noreferrer"
            >
              https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees.html
            </a>
          </li>
        </ul>
        <h4>Finding a Lawyer</h4>
        <p>
          If you are eligible to make a claim for refugee status, you may be
          able to get a lawyer at no cost to you. Each province and territory
          has a
          <a
            href="https://www.justice.gc.ca/eng/fund-fina/gov-gouv/aid-aide.html"
            target="_blank"
            rel="noreferrer"
          >
            legal aid contact
          </a>
          . You should contact Legal Aid as soon as possible. You can also find
          legal aid offices in your area by looking online for a Legal Aid
          office.
        </p>
        <p>
          Not all refugee lawyers accept legal aid certificates. When selecting
          a lawyer to help you file your refugee claim, ask them if they accept
          legal aid.
        </p>
        <p>
          To learn more, go to the{" "}
          <Link to="/settling/legal_aid/">Legal Aid</Link> section of Settling
          in Canada.
        </p>
        <h4>Getting Ready for Your Hearing</h4>
        <p>
          Ready Guide: This downloadable guide to the refugee claim process is
          available in multiple languages. It is an excellent resource to
          understand how to make a refugee claim and how to prepare for your
          hearing.{" "}
          <a
            href="https://refugeeclaim.ca/en/preparation-guide/"
            target="_blank"
            rel="noreferrer"
          >
            https://refugeeclaim.ca/en/preparation-guide/
          </a>
        </p>
        <p>
          Ready Tour: You also have the opportunity to attend a Ready Tour where
          you can learn about what to expect on your hearing day and how you can
          prepare. Registration ahead of time is required.{" "}
          <a
            href="https://refugeeclaim.ca/en/ready-tours/"
            target="_blank"
            rel="noreferrer"
          >
            https://refugeeclaim.ca/en/ready-tours/
          </a>
        </p>
        <h4>Protect Yourself Against Fraud</h4>
        <p>
          Many refugee claimants are unaware that scams and fraud are also here
          in Canada, including immigration fraud. Click here to learn about some
          of the common scams targeting new immigrants.{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/protect-fraud.html"
            target="_blank"
            rel="noreferrer"
          >
            https://www.canada.ca/en/immigration-refugees-citizenship/services/protect-fraud.html
          </a>
        </p>
      </section>
    </Layout>
  )
}

export default Resources
